import { AuthenticatedHttp, GuestHttp } from '@/http/axios';

export const login = ({ email, password, recaptchaToken }) =>
  GuestHttp.post('/token/', {
    email,
    password,
    recaptchaToken,
  })
    .then(({ data }) => {
      if (data.ephemeral_token) {
        return {
          type: '2fa',
          ephemeralToken: data.ephemeral_token,
          methods: data.methods,
        };
      }

      return {
        type: 'direct',
        accessToken: data.access,
        refreshToken: data.refresh,
      };
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        throw new Error('Email ou senha inválidos');
      } else if (
        error?.response?.status === 400 &&
        error?.response?.data?.detail === 'Recaptcha validation failed.'
      ) {
        throw new Error(
          'Detectamos um problema com sua solicitação. Por favor, tente novamente usando um dispositivo ou navegador diferente.'
        );
      } else if (!error?.response?.status) {
        throw new Error('Erro de conexão, tente novamente em alguns minutos.');
      } else if (error?.response?.status === 500) {
        throw new Error('Erro interno no servidor, tente novamente em alguns minutos.');
      } else {
        throw new Error('Erro desconhecido, entre em contato com o suporte.');
      }
    });

export const loginSecondStepService = (ephemeral_token, code) =>
  GuestHttp.post('/token/code/', {
    ephemeral_token,
    code,
  })
    .then(({ data }) => ({
      accessToken: data.access,
      refreshToken: data.refresh,
    }))
    .catch((error) => {
      if (error?.response?.status === 401) {
        console.log('aqui');
        throw new Error('Email ou senha inválidos');
      } else if (
        error?.response?.status === 400 &&
        error?.response?.data?.detail === 'Recaptcha validation failed.'
      ) {
        throw new Error(
          'Detectamos um problema com sua solicitação. Por favor, tente novamente usando um dispositivo ou navegador diferente.'
        );
      } else if (!error?.response?.status) {
        throw new Error('Erro de conexão, tente novamente em alguns minutos.');
      } else if (error?.response?.status === 500) {
        throw new Error('Erro interno no servidor, tente novamente em alguns minutos.');
      } else {
        throw new Error('Erro desconhecido, entre em contato com o suporte.');
      }
    });

export const recoverySendCodeService = ({ email, recaptchaToken }) =>
  GuestHttp.post('/recovery/send-email/', {
    email,
    recaptchaToken,
  }).catch((error) => {
    throw new Error('Email inválido');
  });

export const recoveryChangePasswordService = ({ token, password }) =>
  GuestHttp.post('/recovery/change-password/', {
    token,
    password,
  }).catch((error) => {
    throw new Error('Código inválido');
  });

export const register = (values) =>
  GuestHttp.post('/register/', values).then(({ data: { access } }) => ({
    accessToken: access,
  }));

export const socialLogin = ({ access_token, affiliateCode, provider = 'google' }) =>
  GuestHttp.post(`/login/${provider}/`, {
    access_token,
    affiliateCode,
  }).then(({ data: { access_token: internalAccessToken } }) => ({
    accessToken: internalAccessToken,
  }));

export const me = () =>
  AuthenticatedHttp.get('/user/').then(({ data: user }) => ({
    ...user,
    picture:
      user.picture || 'https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg',
  }));

export const getOtpToken = (token) =>
  token ? GuestHttp.post(`/login/otp/`, { token }).then(({ data }) => data) : { redirect: true };
